<template>
  <div v-if="Object.keys(userInfo).length">
    <b-form @submit.prevent="submitHandler">
      <h6>{{ $t('profile_users_table_id') }} : {{ userInfo.id }}</h6>
      <b-form-group
        :label="$t('profile_users_table_firstName')"
        label-for="name"
        label-cols="4"
      >
        <b-form-input id="name" v-model.trim="userInfo.firstname" />
      </b-form-group>
      <b-form-group
        :label="$t('profile_users_table_email')"
        label-for="email"
        label-cols="4"
      >
        <b-form-input id="email" :value="userInfo.email" disabled />
      </b-form-group>
      <b-form-group
        :label="$t('profile_users_table_university')"
        label-for="univer"
        label-cols="4"
      >
        <b-form-input id="univer" v-model.trim="userInfo.university" />
      </b-form-group>
      <b-form-group
        :label="$t('profile_users_table_occupation')"
        label-for="occupation"
        label-cols="4"
      >
        <b-form-input id="occupation" v-model.trim="userInfo.occupation" />
      </b-form-group>
      <SelectCustom
        :label="$t('profile_users_table_confirmed')"
        name="confirmed"
        :value="String(userInfo.confirmed)"
        :fields="['true', 'false']"
        @change-select-custom="changeHandler"
      />
      <SelectCustom
        :label="$t('profile_users_table_redBook')"
        name="redBook"
        :value="userInfo.redBook"
        :fields="['denied', 'pending', 'allowed', 'adminDenied']"
        @change-select-custom="changeHandler"
      />
      <SelectCustom
        :label="$t('profile_users_table_editor')"
        name="editor"
        :value="userInfo.editor"
        :fields="['denied', 'pending', 'allowed', 'adminDenied']"
        @change-select-custom="changeHandler"
      />
      <SelectCustom
        :label="$t('profile_users_table_admin')"
        name="admin"
        :value="userInfo.admin"
        :fields="['denied', 'pending', 'allowed', 'adminDenied']"
        @change-select-custom="changeHandler"
      />
      <SelectCustom
        add-id="true"
        :label="$t('profile_users_table_role')"
        name="role"
        :value="JSON.stringify(userInfo.role)"
        :fields="['user', 'editor', 'admin']"
        @change-select-custom="changeHandler"
      />
      <b-button size="sm" block variant="success" type="submit">
        {{ $t('profile_users_table_save') }}
      </b-button>
    </b-form>
  </div>
</template>

<script>
import SelectCustom from './SelectCustom'
export default {
  components: { SelectCustom },
  props: {
    id: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      userInfo: {}
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUserById(this.id)
    }
  },
  mounted() {
    this.userInfo = this.user
  },
  methods: {
    changeHandler({ name, selected }) {
      if (name === 'role') {
        this.userInfo[name] = JSON.parse(selected)
        return
      }
      this.userInfo[name] = selected
    },
    async submitHandler() {
      await this.$store.dispatch('requestEditUser', this.userInfo)
      this.$root.$emit('bv::hide::modal', 'editModal')
    }
  }
}
</script>
