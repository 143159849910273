<template>
  <div class="usersList">
    <h5 class="text-left ml-3 mb-3">{{ $t('profile_users_title') }}</h5>

    <div class="table-wrap px-3">
      <b-input-group>
        <b-input-group-prepend>
          <span class="input-group-text">
            <b-icon icon="search" />
          </span>
        </b-input-group-prepend>
        <b-form-input
          v-model.trim="filter"
          type="search"
          :placeholder="$t('profile_users_table_search')"
          @input="searchUsersOnTimeout"
        />
      </b-input-group>

      <b-table
        id="users"
        responsive
        hover
        :items="getUsers"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :per-page="perPage"
        :filter="filter"
        class="mt-2 flex-grow-1"
        no-local-sorting
      >
        <template #head()="data">
          {{ $t('profile_users_table_' + data.label) }}
        </template>
        <template v-slot:cell(email)="row">
          <div class="overflow">{{ row.value }}</div>
        </template>
        <template v-slot:cell(university)="row">
          <div class="overflow">{{ row.value }}</div>
        </template>
        <template v-slot:cell(occupation)="row">
          <div class="overflow">{{ row.value }}</div>
        </template>
        <template v-slot:cell(confirmed)="row">
          {{ $t('profile_users_table_status_' + row.value) }}
        </template>
        <template v-slot:cell(redBook)="row">
          {{ $t('profile_users_table_status_' + row.value) }}
        </template>
        <template v-slot:cell(editor)="row">
          {{ $t('profile_users_table_status_' + row.value) }}
        </template>
        <template v-slot:cell(confirmed)="row">
          {{ $t('profile_users_table_status_' + row.value) }}
        </template>
        <template v-slot:cell(admin)="row">
          <div class="overflow">
            {{ $t('profile_users_table_status_' + row.value) }}
          </div>
        </template>
        <template v-slot:cell(role)="row">
          {{ $t('profile_users_table_role_' + row.value.name) }}
        </template>
        <template v-slot:cell(actions)="row">
          <b-button
            size="sm"
            variant="success"
            @click="info(row.item.id, row.index, $event.target)"
          >
            <b-icon icon="pencil" class="mx-1" />
          </b-button>
        </template>
      </b-table>
      <b-row class="mt-3 mt-auto">
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="getCount"
            :per-page="perPage"
            aria-controls="users"
            first-number
            last-number
            @input="searchUsers"
          ></b-pagination>
        </b-col>
        <b-col sm="4">
          <b-form-group
            :label="$t('profile_users_table_perPage')"
            label-cols="8"
            label-align="right"
            label-size="sm"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              @input="searchUsers"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <Modal
        :title="$t('profile_users_table_modal_title')"
        :id-modal="'editModal'"
        size="md"
      >
        <template #modalBody>
          <EditForm :id="infoModal.id" />
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import Modal from '@/components/Modal'
import EditForm from '@/components/Users/EditForm'
import { mapGetters } from 'vuex'
let timer = null

export default {
  components: {
    Modal,
    EditForm
  },
  data() {
    return {
      sortBy: 'id',
      sortDesc: false,
      perPage: 5,
      currentPage: 1,
      totalRows: null,
      filter: '',
      pageOptions: [5, 10, 15],
      fields: [
        {
          key: 'firstname',
          sortable: true,
          thClass: 'thead_',
          label: 'firstName'
        },
        { key: 'email', sortable: true, thClass: 'thead_', label: 'email' },
        {
          key: 'university',
          sortable: true,
          thClass: 'thead_',
          label: 'university'
        },
        {
          key: 'occupation',
          sortable: true,
          thClass: 'thead_',
          label: 'occupation'
        },
        {
          key: 'confirmed',
          sortable: true,
          thClass: 'thead_',
          label: 'confirmed'
        },
        {
          key: 'redBook',
          sortable: true,
          thClass: 'thead_',
          label: 'redBook'
        },
        {
          key: 'editor',
          sortable: true,
          thClass: 'thead_',
          label: 'editor'
        },
        { key: 'admin', sortable: true, thClass: 'thead_', label: 'admin' },
        { key: 'role', sortable: true, thClass: 'thead_', label: 'role' },
        {
          key: 'actions',
          label: 'actions',
          thClass: 'thead_'
        }
      ],
      infoModal: {
        id: null
      }
    }
  },
  computed: {
    ...mapGetters(['getUsers', 'getCount']),
    options() {
      return {
        perPage: this.perPage,
        name: this.filter,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      }
    }
  },
  watch: {
    sortBy: function() {
      this.searchUsers()
    },
    sortDesc: function() {
      this.searchUsers()
    }
  },
  mounted() {
    this.searchUsers()
  },
  methods: {
    info(item, index, button) {
      this.infoModal.id = item
      this.$root.$emit('bv::show::modal', 'editModal', button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = {}
    },
    searchUsersOnTimeout(value) {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      if (value.length > 2) {
        timer = setTimeout(() => {
          this.$store.dispatch('requestUsers', this.options)
        }, 1000)
      }
    },
    searchUsers() {
      this.$store.dispatch('requestUsers', this.options)
    }
  }
}
</script>

<style lang="scss" scoped>
.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}
.usersList {
  padding: 20px 0;
  background-color: #fff;
  /deep/.thead_ {
    background: #f6f6f6;
    color: #373a3c;
    font-size: 0.8125rem;
    border: none;
    vertical-align: middle;
    border-radius: 4px 4px 0px 0px;
    @media (max-width: 426px) {
      line-height: 1.5;
    }
  }
  /deep/.table-wrap {
    display: flex;
    flex-direction: column;
    min-height: 380px;
  }
  /deep/.input-group {
    color: #ababab;
    input {
      border-left: none;
    }
    .input-group-text {
      border: none;
      background: #f7f7f7;
      padding: 0.375rem 0.2rem 0.375rem 0.75rem;
    }
  }

  /deep/.table > tbody > tr > td {
    border-top: none;
    border-bottom: 1px solid #f1f1f1;
    text-align: center;
    font-size: 0.85rem;
    vertical-align: middle;
  }
}
</style>
