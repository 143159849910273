<template>
  <b-form-group :label="label" :label-for="name" label-cols="4">
    <b-form-select
      :id="name"
      v-model="selected"
      :options="options"
      value-field="item"
      text-field="name"
      @input="$emit('change-select-custom', { name, selected })"
    />
  </b-form-group>
</template>

<script>
export default {
  props: ['value', 'fields', 'label', 'add-id', 'name'],
  data() {
    return {
      selected: this.value,
      options: []
    }
  },
  computed: {
    formatOptions() {
      return this.fields.map(field => ({
        item: field,
        name: this.$t('profile_users_table_status_' + field)
      }))
    },
    formatOptionsId() {
      return this.fields.map((field, index) => ({
        item: JSON.stringify({ id: index + 1, name: field }),
        name: this.$t('profile_users_table_role_' + field)
      }))
    }
  },
  mounted() {
    if (this.addId) {
      this.options = this.formatOptionsId
    } else {
      this.options = this.formatOptions
    }
  }
}
</script>
